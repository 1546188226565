<template>
    <div class="vendorEdit desktop">
        <Header :type="headerType"/> 
        <VendorDataForm :editType="editType"/>
        <div class="passwordChange" v-if="passwordAlert">
            <img src="@/assets/img/icon/locker.png" alt="">
            <h4>Password updated<br>successfully !</h4>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header'
import VendorDataForm from '@/components/VendorDataForm'
export default {
    name:'VenderEdit',
    data(){
        return {
            headerType:'normal_M',
            editType:'edit',
            passwordAlert:false
        }
    },
    components:{
        Header,VendorDataForm
    },
    computed:{

    },
    mounted() {
    },
}
</script>
<style lang="scss" scoped> 
    @import '@/assets/scss/_variables.scss';
    .vendorEdit{
        padding-bottom:88px;
        background-color: $lightPurple;
        position: relative;
        .passwordChange{
            width:326px;
            position: fixed;
            border-radius: 9px;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            background-color:#fff;
            box-shadow: 0 4px 10px 4px rgb(0 0 0 / 15%);
            padding: 5.5% 0;
            img{
                //width:25%;
                width:67px;
            }
            h4{
                font-size: 20px;
            }
        }
    }
</style>